import { Ref, memo, useContext, forwardRef } from 'react';
import { GlobalContext } from '../../context';
import { SPECIAL_CODES } from '../../utils/data';

interface CmiHiddenFormProps {}

const CmiHiddenForm = forwardRef(
  (props: CmiHiddenFormProps, ref: Ref<HTMLFormElement> | null) => {
    const {
      state: {
        cmiProps: {
          actionGateUrl,
          clientId,
          storeType,
          tranType,
          currency,
          okUrl,
          failUrl,
          callBackUrl,
          rnd,
          billToCompany,
          cfCanal,
          cfOperateur,
          cfMode,
        },
        contactInfo: { email },
        nameInfo: { lastName, firstName },
        hashData,
        unpaidTaxes,
      },
    } = useContext(GlobalContext);

    // console.log({ cmiProps });

    return (
      <form method="post" action={actionGateUrl} ref={ref}>
        <input type="hidden" name="clientid" value={clientId} />
        <input type="hidden" name="storetype" value={storeType} />
        <input type="hidden" name="hash" value={hashData} />
        <input type="hidden" name="trantype" value={tranType} />
        <input type="hidden" name="amount" value={unpaidTaxes?.totalTTC} />
        <input type="hidden" name="currency" value={currency} />
        <input type="hidden" name="oid" value={unpaidTaxes?.oid || ''} />
        <input type="hidden" name="okUrl" value={okUrl} />
        <input type="hidden" name="failUrl" value={failUrl} />
        <input type="hidden" name="CallbackUrl" value={callBackUrl} />
        <input type="hidden" name="lang" value="fr" />
        <input type="hidden" name="rnd" value={rnd} />
        <input type="hidden" name="hashAlgorithm" value="ver3" />
        <input type="hidden" name="email" value={email} />
        <input type="hidden" name="BillToCompany" value={billToCompany} />
        <input
          type="hidden"
          name="BillToName"
          value={`${lastName} ${firstName}`}
        />
        <input type="hidden" name="fid" value={SPECIAL_CODES.CODE_CREANCIER} />
        <input type="hidden" name="cid" value={SPECIAL_CODES.CODE_CREANCE} />
        <input type="hidden" name="CF_Canal" value={cfCanal} />
        <input type="hidden" name="CF_Operateur" value={cfOperateur} />
        <input type="hidden" name="CF_Mode" value={cfMode} />
        <input type="hidden" name="total1" value={unpaidTaxes?.total1 || ''} />
        <input
          type="hidden"
          name="cf_params_global"
          value={unpaidTaxes?.weirdParams || ''}
        />
        <input type="hidden" name="id1" value={unpaidTaxes?.id1 || ''} />
        <input type="hidden" name="id2" value={unpaidTaxes?.id2 || ''} />
        <input type="hidden" name="desc1" value={unpaidTaxes?.desc1 || ''} />
        <input type="hidden" name="desc2" value={unpaidTaxes?.desc2 || ''} />
        <input type="hidden" name="total2" value={unpaidTaxes?.total2 || ''} />
        <input type="hidden" name="autoRedirect" value="true" />
        <input
          type="hidden"
          name="CF_nbArticle"
          value={unpaidTaxes?.nbrOfArticles || ''}
        />
        <input
          type="hidden"
          name="itemNumber1"
          value={unpaidTaxes?.id1 || ''}
        />
        <input
          type="hidden"
          name="itemNumber2"
          value={unpaidTaxes?.id2 || ''}
        />
        <input type="hidden" name="encoding" value="UTF-8" />
      </form>
    );
  },
);

export default memo(CmiHiddenForm);
