import styled from 'styled-components/macro';

export const PhoneWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const PhoneInputWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 30px;
  @media screen and (max-width: 640px) {
    margin-left: 0;
  }
`;
