import { memo, useCallback, useContext, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../context';
import {
  getTaxDescriptionElements,
  makePlateNumber,
} from '../../utils/helpers';
import { ButtonsContainer } from './styles';
import Button from '../../components/Button';
import SvgIcon from '../../components/SvgIcon';
import Arrow from '../../components/svgs/Arrow';
import { previousStep } from '../../context/actions';
import CmiHiddenForm from './CmiHiddenForm';
import { ThemeInterface } from '../../components/Theme';
import useUpdateClient from '../../hooks/useUpdateClient';
import { taxingFormMessages } from './messages';

const RecapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RecapSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h2 {
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;
  }

  ul {
    margin-top: 10px;

    li {
      font-size: 16px;
      padding: 4px;

      strong {
        font-weight: bold;
      }
    }
  }

  .tax-list {
    display: flex;
    flex-direction: column;
    li {
      min-width: 60%;
      min-height: 50px;
      padding: 10px;
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 20%);
      font-weight: bold;
      span:first-child {
        text-transform: uppercase;
      }
      &:last-child {
        color: red;
        font-weight: bold;
        font-size: 18px;
        box-shadow: none;
        border: 1px solid red;
        text-transform: uppercase;
      }
    }
  }
`;

const RecapInfoItem = styled.div<{
  highlighted?: boolean;
  textTransform?: 'lowercase' | 'uppercase' | 'capitalize';
}>`
  font-family: ${props => props.theme.fonts.Montserrat};
  line-height: 18px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .recap-label {
    font-size: 14px;
    ${({ textTransform }) =>
      textTransform && `text-transform : ${textTransform}`}
  }
  .recap-info {
    font-weight: ${props => props.theme.fontWeights.Montserrat.extraBold};
    text-transform: uppercase;
    color: ${props =>
      props.highlighted
        ? props.theme.colors.primary
        : props.theme.colors.secondary};
  }
`;
const PersoRecapInfoContainer = styled.div`
  background-color: ${props => props.theme.colors.lighterGray};
  padding: 20px;
  margin-bottom: 30px;
  width: calc(100% + 100px);
  display: flex;
  transition: background-color 0.2s ease;
  ${RecapInfoItem} {
    width: 33.33%;

    @media only screen and (max-width: 1024px) {
      width: 50%;
      margin: 5px 0;
    }
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }
  ${({ theme }) =>
    theme.mode === 'dark' && `box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);`}

  @media only screen and (max-width: 1024px) {
    width: calc(100% + 40px);
    flex-wrap: wrap;
    padding: 10px 20px;
  }
  @media only screen and (max-width: 640px) {
    flex-direction: column;
    width: 100%;
  }
`;

const RecapInfoContainerItem = styled.div<{
  highlighted?: boolean;
  fullSize?: boolean;
  withDetails?: boolean;
}>`
  border: 1px solid
    ${props => (props.highlighted ? props.theme.colors.primary : '#f0f0f0')};
  border-radius: 8px;
  ${({ withDetails }) =>
    withDetails &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
  min-height: 64px;
  padding: 10px 10px 10px 25px;
  display: flex;
  align-items: center;
  width: ${({ fullSize = false }) => (fullSize ? '100%' : '49%')};
  margin: ${({ withDetails }) => (withDetails ? '5px 0 0 0' : '5px 0')};
`;
const RecapInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${RecapInfoContainerItem} {
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }
  ${PersoRecapInfoContainer} {
    width: 100%;
    margin-bottom: 5px;
    border: 1px solid #f0f0f0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transform: translateY(-1px);
  }
  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

function Recap() {
  const {
    state: { carRegistration, nameInfo, contactInfo, themeMode, unpaidTaxes },
    dispatch,
  } = useContext(GlobalContext);

  const { t } = useTranslation();

  const cmiFormRef = useRef<HTMLFormElement | null>(null);

  const handleGoToPreviousStep = useCallback(
    () => dispatch(previousStep()),
    [dispatch],
  );

  const theme = useTheme();
  const { handleUpdateClient } = useUpdateClient();

  return (
    <RecapContainer>
      <RecapSection>
        <PersoRecapInfoContainer>
          <RecapInfoItem textTransform="lowercase">
            <span className="recap-label">
              {t(taxingFormMessages.taxingProcessForm.fullNameStep.title())}
            </span>
            <span className="recap-info">
              {`${nameInfo.firstName} ${nameInfo.lastName}`}
            </span>
          </RecapInfoItem>
          <RecapInfoItem textTransform="lowercase">
            <span className="recap-label">
              {t(
                taxingFormMessages.taxingProcessForm.contactInfoStep.fields.telephoneLabel(),
              )}
            </span>
            <span className="recap-info">{contactInfo.phoneNumber}</span>
          </RecapInfoItem>
          <RecapInfoItem textTransform="lowercase">
            <span className="recap-label">
              {t(
                taxingFormMessages.taxingProcessForm.contactInfoStep.fields.emailLabel(),
              )}
            </span>
            <span className="recap-info">{contactInfo.email}</span>
          </RecapInfoItem>
        </PersoRecapInfoContainer>
        <RecapInfoContainer>
          <RecapInfoContainerItem>
            <RecapInfoItem>
              <span className="recap-label">
                {t(
                  taxingFormMessages.taxingProcessForm.carRegistrationStep.fields.carPlateNumberLabel(),
                )}
              </span>
              <span className="recap-info">
                {makePlateNumber(carRegistration)}
              </span>
            </RecapInfoItem>
          </RecapInfoContainerItem>
          <RecapInfoContainerItem>
            <RecapInfoItem>
              <span className="recap-label">
                {t(
                  taxingFormMessages.taxingProcessForm.carRegistrationStep.fields.fiscalPowerLabel(),
                )}
              </span>
              <span className="recap-info">
                {carRegistration.fiscalHorsePower}
              </span>
            </RecapInfoItem>
          </RecapInfoContainerItem>
          <RecapInfoContainerItem>
            <RecapInfoItem>
              <span className="recap-label">
                {t(
                  taxingFormMessages.taxingProcessForm.carRegistrationStep.fields.fuelLabel(),
                )}
              </span>
              <span className="recap-info">{carRegistration.fuel}</span>
            </RecapInfoItem>
          </RecapInfoContainerItem>
          {unpaidTaxes?.car && (
            <RecapInfoContainerItem>
              <RecapInfoItem>
                <span className="recap-label">
                  {t(
                    taxingFormMessages.taxingProcessForm.carRegistrationStep.fields.carLabel(),
                  )}
                </span>
                <span className="recap-info">{unpaidTaxes?.car}</span>
              </RecapInfoItem>
            </RecapInfoContainerItem>
          )}
          <RecapInfoContainerItem fullSize withDetails>
            <RecapInfoItem>
              <span className="recap-label">
                TSVA {unpaidTaxes?.invoiceDate}
              </span>
              <span className="recap-info">{unpaidTaxes?.totalTaxHT} Dhs</span>
            </RecapInfoItem>
          </RecapInfoContainerItem>

          <PersoRecapInfoContainer>
            {unpaidTaxes?.listTax?.description &&
              getTaxDescriptionElements(unpaidTaxes?.listTax?.description).map(
                e => (
                  <RecapInfoItem>
                    <span className="recap-label">{e.title}</span>
                    <span className="recap-info">
                      {e.amount}
                      Dhs
                    </span>
                  </RecapInfoItem>
                ),
              )}
          </PersoRecapInfoContainer>
          <RecapInfoContainerItem>
            <RecapInfoItem>
              <span className="recap-label">
                {t(
                  taxingFormMessages.taxingProcessForm.paymentStep.content.fees(),
                )}
              </span>
              <span className="recap-info">{unpaidTaxes?.fees} Dhs</span>
            </RecapInfoItem>
          </RecapInfoContainerItem>
          <RecapInfoContainerItem highlighted>
            <RecapInfoItem highlighted>
              <span className="recap-label">
                {t(
                  taxingFormMessages.taxingProcessForm.paymentStep.content.amount(),
                )}{' '}
              </span>
              <span className="recap-info">{unpaidTaxes?.totalTTC} Dhs</span>
            </RecapInfoItem>
          </RecapInfoContainerItem>
        </RecapInfoContainer>
      </RecapSection>
      <CmiHiddenForm ref={cmiFormRef} />
      <ButtonsContainer>
        <Button
          onClick={handleGoToPreviousStep}
          big
          rounded
          ghost
          htmlType="button"
          title={t(
            taxingFormMessages.taxingProcessForm.actionButtons.goBack.title(),
          )}
          icon={
            <SvgIcon
              iconColor={themeMode === 'light' ? 'inherit' : '#fff'}
              style={{
                transform: 'rotate(180deg)',
                marginRight: (theme as ThemeInterface).rtl ? 0 : 10,
                marginLeft: (theme as ThemeInterface).rtl ? 10 : 0,
              }}
            >
              <Arrow />
            </SvgIcon>
          }
          iconPosition="left"
        />
        <Button
          big
          rounded
          primary
          htmlType="button"
          title={t(taxingFormMessages.taxingProcessForm.paymentStep.action())}
          onClick={async e => {
            e.preventDefault();
            // console.log('payer maintemnt clicked !', { cmiFormRef });
            await handleUpdateClient('PAIEAMENT_NON_ATTEINT', () =>
              cmiFormRef?.current?.submit(),
            );
            // cmiFormRef?.current?.submit();
          }}
        />
      </ButtonsContainer>
    </RecapContainer>
  );
}

export default memo(Recap);
