import { memo } from 'react';
import SEO from '../components/SEO';
import TaxingFormProcess from '../features/TaxingFormProcess';

const Payment = () => (
  <>
    <SEO
      keywords={[
        'paiement vignette maroc',
        'ma vignette',
        'payer en ligne vignette maroc',
        'paiement vignette maroc en ligne',
        'payer vignette en ligne',
        'vignette en ligne',
        'ma vignette maroc',
        'paiement en ligne maroc',
        'paiement internet maroc',
        'impot voiture maroc',
        'taxe voiture maroc',
        'payer taxe voiture maroc',
        'payer dariba maroc',
        'payer dariba en ligne',
        'payer dariba',
        'vignette 2022',
        'payer vignette 2022',
        'payer vignette 2022 maroc',
      ]}
    />
    <TaxingFormProcess />
  </>
);

export default memo(Payment);
