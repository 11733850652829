import { ChangeEvent, Dispatch, memo, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CustomInput from '../../components/CustomInput';
import { InputGroup } from './styles';
import Select from '../../components/Select';
import GRecaptcha from '../../components/GRecaptcha';
import CheckBox from '../../components/CheckBox';
import { setPhoneCountry, setRecaptcha } from '../../context/actions';
import { Action, themeType } from '../../context/types';
import PhoneInput from '../../components/PhoneInput';

interface InputFieldProps {
  name: string;
  type:
    | 'text'
    | 'email'
    | 'number'
    | 'select'
    | 'phone'
    | 'recaptcha'
    | 'checkbox';

  placeholder?: string;
  // register: any; // TODO : change type
  // errors: FieldError | undefined;
  options?: Array<{ value: string; label: string }>;
  rules?: any; // TODO : figure out the type
  disablePaste?: boolean;
  limit?: number;
  format?: string;
  dispatch: Dispatch<Action>;
  themeMode: themeType;
  phoneCountry: string;
  extraLabel: string;
}

function InputField({
  name,
  type,
  placeholder,
  options = [],
  rules = {},
  disablePaste = false,
  limit,
  format,
  dispatch,
  themeMode = 'light',
  phoneCountry,
  extraLabel = '',
}: InputFieldProps) {
  const {
    formState: { errors: allErrors },
    setValue,
    control,
  } = useFormContext();

  const errors = allErrors[name];

  const handleChangeRecaptchaValue = useCallback(
    (onChange, value) => {
      // TODO : this is a hack to be refactored
      onChange(value);
      dispatch(setRecaptcha(value || ''));
    },
    [dispatch],
  );

  switch (type) {
    case 'email':
    case 'text':
    case 'number':
      return (
        <InputGroup>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <CustomInput
                type={type}
                placeholder={placeholder}
                limit={limit}
                format={format}
                isForPaymentProcess
                {...field}
                {...(disablePaste && {
                  onPaste: e => {
                    e.preventDefault();
                    return false;
                  },
                })}
              />
            )}
          />
          {errors && <p className="text-error">{errors.message}</p>}
        </InputGroup>
      );

    case 'select':
      return (
        <InputGroup>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Select {...field} options={options} defaultValue={options[0]} />
            )}
          />

          {errors && <p className="text-error">{errors.message}</p>}
        </InputGroup>
      );
    case 'recaptcha':
      return (
        <InputGroup>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <GRecaptcha
                onChange={v => handleChangeRecaptchaValue(field.onChange, v)}
                themeMode={themeMode}
              />
            )}
          />

          {errors && <p className="text-error">{errors.message}</p>}
        </InputGroup>
      );
    case 'checkbox':
      return (
        <InputGroup>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <CheckBox
                value={field.value}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  field.onChange(e.target.checked)
                }
              />
            )}
          />
          {errors && <p className="text-error">{errors.message}</p>}
        </InputGroup>
      );

    case 'phone':
      return (
        <InputGroup>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <PhoneInput
                {...field}
                placeholder={placeholder}
                country={phoneCountry}
                setCountry={(v: string) => {
                  dispatch(setPhoneCountry(v));
                  setValue(name, '');
                }}
                countryLabel={extraLabel}
              />
            )}
          />

          {errors && <p className="text-error">{errors.message}</p>}
        </InputGroup>
      );

    default:
      return null;
  }
}

export default memo(InputField);
