import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../components/Layout/MainLayout';
import { GlobalContext } from '../../context';
import { multiStepForm } from './steps';
import { ButtonsContainer, StepForm, StepFormContent } from './styles';
import InputField from './InputField';
import { taxingFromFieldsType } from '../../context/types';
import Button from '../../components/Button';
import SvgIcon from '../../components/SvgIcon';
import { ThemeInterface } from '../../components/Theme';
import Arrow from '../../components/svgs/Arrow';
import { FIELD_NAMES, FORM_STEPS } from '../../utils/data';
import {
  nextStep,
  previousStep,
  setCarRegistrationValues,
  setContactInfo,
  setHasAgreedOnTerms,
  setNameInfo,
  setRecaptcha,
} from '../../context/actions';
// @ts-ignore
import usePayment from '../../hooks/usePayment';
import CarTaxStep from './CarTaxStep';
import useKeyPressListener from '../../hooks/useKeyPressListener';
import { taxingFormMessages } from './messages';

function TaxingFormProcess() {
  const {
    state: {
      step,
      carRegistration,
      nameInfo,
      contactInfo,
      recaptcha,
      hasAgreedOnTerms,
      loading,
      themeMode,
      phoneCountry,
    },
    dispatch,
  } = useContext(GlobalContext);

  const theme = useTheme();
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement | null>(null);

  const { handlePaymentRequest } = usePayment();

  const currentStepForm = useMemo(() => multiStepForm[step - 1], [step]);

  const methods = useForm<taxingFromFieldsType>({
    mode: 'all',
    defaultValues: {
      ...carRegistration,
      ...nameInfo,
      ...contactInfo,
      recaptcha,
      hasAgreedOnTerms,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = methods;

  const submitWithEnter = useCallback(() => {
    formRef.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  }, [formRef.current]);

  const handleEnterKeyPressed = useCallback(
    e => {
      if (e.keyCode === 13) {
        e.preventDefault();
        console.log('ENTER PRESSED ....');
        submitWithEnter();
      }
    },
    [submitWithEnter],
  );

  useKeyPressListener({
    actionListener: handleEnterKeyPressed,
    deps: [formRef.current],
  });

  const isValid = () => {
    // console.clear();
    console.log(
      '%c TRIGGER ERRORS',
      'background-color: #red;font-size:26px; color: #bada55',
    );
    // CLEAR ERRORS FIRST

    switch (currentStepForm.name) {
      case FORM_STEPS.FULL_NAME:
        console.log({
          vals: Object.values(getValues(['firstName', 'lastName'])),
        });
        return Object.values(getValues(['firstName', 'lastName'])).every(
          v => !!v,
        );

      case FORM_STEPS.CONTACT_INFO:
        // last step
        console.log({
          vals: Object.values(
            getValues([
              'email',
              'phoneNumber',
              'confirmedEmail',
              'recaptcha',
              'hasAgreedOnTerms',
            ]),
          ),
        });
        return Object.values(
          getValues([
            'email',
            'phoneNumber',
            'confirmedEmail',
            'recaptcha',
            'hasAgreedOnTerms',
          ]),
        ).every(v => !!v);

      case FORM_STEPS.CAR_REGISTRATION:
        console.log({
          vals: Object.values(
            getValues([
              'carRegistrationChunk1',
              'carRegistrationChunk2',
              'carRegistrationChunk3',
              'fuel',
              'year',
              'fiscalHorsePower',
            ]),
          ),
        });
        return Object.values(
          getValues([
            'carRegistrationChunk1',
            'carRegistrationChunk2',
            'carRegistrationChunk3',
            'fuel',
            'year',
            'fiscalHorsePower',
          ]),
        ).every(v => !!v);

      default:
        return false;
    }
  };

  useEffect(() => {
    clearErrors();
    if (step === multiStepForm.length - 1) {
      // reset recaptcha
      dispatch(setRecaptcha(''));
      setValue('recaptcha', '');
    }
  }, [step]);

  const onSubmit: SubmitHandler<taxingFromFieldsType> = useCallback(
    async data => {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        confirmedEmail,
        carRegistrationChunk1,
        carRegistrationChunk2,
        carRegistrationChunk3,
        fiscalHorsePower,
        fuel,
        year,
        recaptcha: recaptchaToken,
        hasAgreedOnTerms: hasAgreed,
      } = data;

      // console.log('SUBMIT', { data });

      switch (currentStepForm.name) {
        case FORM_STEPS.FULL_NAME:
          dispatch(setNameInfo({ firstName, lastName }));
          dispatch(nextStep());
          break;

        case FORM_STEPS.CONTACT_INFO:
          // last step
          dispatch(setContactInfo({ email, phoneNumber, confirmedEmail }));
          // dispatch(nextStep());
          dispatch(setRecaptcha(recaptchaToken));
          dispatch(setHasAgreedOnTerms(hasAgreed));

          await handlePaymentRequest(data);

          break;

        case FORM_STEPS.CAR_REGISTRATION:
          dispatch(
            setCarRegistrationValues({
              carRegistrationChunk1,
              carRegistrationChunk2,
              carRegistrationChunk3,
              fiscalHorsePower,
              fuel,
              year,
            }),
          );

          dispatch(nextStep());
          break;

        default:
          break;
      }
    },
    [handleSubmit, currentStepForm, dispatch, handlePaymentRequest],
  );

  const handleGoToPreviousStep = useCallback(
    () => dispatch(previousStep()),
    [dispatch],
  );

  const getRules = useCallback(
    (name: string, rules: object) => {
      switch (name) {
        case FIELD_NAMES.CONFIRMED_EMAIL_ADDRESS:
          return {
            ...rules,
            validate: (v: string) =>
              // @ts-ignore
              rules.validate(getValues(FIELD_NAMES.EMAIL_ADDRESS as any))(v),
          };
        case FIELD_NAMES.PHONE_NUMBER:
          return {
            ...rules,
            validate: (v: string) =>
              // @ts-ignore
              rules.validate(phoneCountry)(v),
          };
        default:
          return rules;
      }
    },
    [getValues, phoneCountry],
  );

  const isSubmitBtnDisabled = useMemo(
    () => Object.keys(errors).length > 0 || loading || !isValid(),
    [loading, errors, isValid],
  );

  const iconColor = useMemo(
    () => (themeMode === 'light' ? 'inherit' : '#fff'),
    [themeMode],
  );

  // console.log({ step, currentStepForm });
  /* console.log({
    errors,
    step,
    isValid,
    recaptchaFORM: getValues('recaptcha'),
    recaptcha,
  }); */
  // console.log({ carRegistration, nameInfo, contactInfo, recaptcha });

  // console.log('MULTI STEP FORM : ', { errors, isValid: isValid() });

  return (
    <MainLayout
      step={step}
      totalSteps={multiStepForm.length}
      title={currentStepForm.title}
      subtitle={currentStepForm.subtitle}
      backgroundImage={currentStepForm.backgroundImage}
    >
      <FormProvider {...methods}>
        {currentStepForm.fields ? (
          <StepForm onSubmit={handleSubmit(onSubmit)} as="form" ref={formRef}>
            <StepFormContent>
              {currentStepForm.renderAsCompInsideForm && <CarTaxStep />}
              {currentStepForm.fields.map(
                ({
                  name,
                  type,
                  placeholder,
                  rules,
                  extraLabel = '',
                  ...rest
                }: any) => (
                  <InputField
                    key={name}
                    placeholder={placeholder as string}
                    type={type as any}
                    name={name}
                    rules={getRules(name, rules)}
                    dispatch={dispatch}
                    themeMode={themeMode}
                    phoneCountry={phoneCountry}
                    extraLabel={extraLabel}
                    {...rest}
                  />
                ),
              )}
            </StepFormContent>
            <ButtonsContainer>
              {step === 1 ? (
                <span />
              ) : (
                <Button
                  onClick={handleGoToPreviousStep}
                  big
                  rounded
                  ghost
                  htmlType="button"
                  title={t(
                    taxingFormMessages.taxingProcessForm.actionButtons.goBack.title(),
                  )}
                  icon={
                    <SvgIcon
                      iconColor={iconColor}
                      style={{
                        transform: 'rotate(180deg)',
                        marginRight: (theme as ThemeInterface).rtl ? 0 : 10,
                        marginLeft: (theme as ThemeInterface).rtl ? 10 : 0,
                      }}
                    >
                      <Arrow />
                    </SvgIcon>
                  }
                  iconPosition="left"
                />
              )}
              <Button
                disabled={isSubmitBtnDisabled}
                big
                rounded
                primary
                htmlType="submit"
                title={t(
                  taxingFormMessages.taxingProcessForm.actionButtons.submit.title(),
                )}
                subtitle={t(
                  taxingFormMessages.taxingProcessForm.actionButtons.submit.subtitle(),
                )}
                icon={
                  <SvgIcon
                    iconColor="#fff"
                    style={{
                      marginLeft: (theme as ThemeInterface).rtl ? 0 : 10,
                      marginRight: (theme as ThemeInterface).rtl ? 10 : 0,
                    }}
                  >
                    <Arrow />
                  </SvgIcon>
                }
                iconPosition="right"
              />
            </ButtonsContainer>
          </StepForm>
        ) : (
          currentStepForm.render()
        )}
      </FormProvider>
    </MainLayout>
  );
}

export default memo(TaxingFormProcess);
