import { ChangeEvent, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SECTIONS_PATHS } from '../../utils/data';
import { messages as globalMessages } from '../messages';

const StyledCheckboxContainer = styled.div`
  padding-right: 10px;
  display: flex;
  align-items: center;
  .fake-input-container {
    width: 22px;
    height: 22px;
    border: 1px solid ${props => props.theme.colors.secondaryBackground};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: ${props => (props.theme.rtl ? 0 : '5px')};
    margin-left: ${props => (props.theme.rtl ? '5px' : 0)};
    span {
      width: 6px;
      height: 6px;
      background: ${props => props.theme.colors.secondaryBackground};
      border: 2px solid transparent;
      transition: all 0.2s ease;
    }
    input {
      display: none;
      &:checked {
        & + span {
          border-color: ${props => props.theme.colors.secondary};
          background: ${props => props.theme.colors.secondary};
        }
      }
    }
  }
`;

interface CheckBoxProps {
  value: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
}

function CheckBox({ onChange, value }: CheckBoxProps) {
  const { t } = useTranslation();
  return (
    <StyledCheckboxContainer>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="checkbox" className="fake-input-container">
        <input
          type="checkbox"
          id="checkbox"
          aria-required="true"
          checked={value}
          onChange={onChange}
        />
        <span />
      </label>
      <p>
        {t(globalMessages.custom.acceptText())}{' '}
        <a
          href={`/${SECTIONS_PATHS.LEGAL_NOTICE}`}
          target="_blank"
          rel="noreferrer"
        >
          {t(globalMessages.navigationBar.legalNoticeLinkTitle())}{' '}
        </a>{' '}
        {t(globalMessages.custom.acceptExtraText())}{' '}
        <a href={`/${SECTIONS_PATHS.TERMS}`} target="_blank" rel="noreferrer">
          {t(globalMessages.navigationBar.termsAndConditionsLinkTitle())}{' '}
        </a>
      </p>
    </StyledCheckboxContainer>
  );
}

export default memo(CheckBox);
