import { useEffect } from 'react';

interface CustomHookType {
  // eslint-disable-next-line no-unused-vars
  actionListener: (event: MouseEvent) => void;
  deps: Array<any>;
  node?: any;
}

const useKeyPressListener = ({
  actionListener,
  deps = [],
  node,
}: CustomHookType) => {
  useEffect(() => {
    const elem = node || window;
    elem.removeEventListener('keydown', actionListener);
    elem.addEventListener('keydown', actionListener);
    return () => elem.removeEventListener('keydown', actionListener);
  }, deps);
};

export default useKeyPressListener;
