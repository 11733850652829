import { Translation } from 'react-i18next';
import {
  FIELD_NAMES,
  FORM_STEPS,
  /*  FUEL_OPTIONS,
  YEAR_OPTIONS, */
} from '../../utils/data';
import { taxingFormMessages } from './messages';
import { messages as globalMessages } from '../../components/messages';
// import bgleft from '../../images/bg-left.png';
import taxStepBg from '../../images/side-bg/car-bg-left.png';
import contactBg from '../../images/side-bg/coordinates-bg-left.png';
import infoBg from '../../images/side-bg/infos-bg-left.png';
import recapBg from '../../images/side-bg/recap-bg-left.png';

import Recap from './Recap';
import { validatePhoneNumber } from '../../utils/helpers';

// eslint-disable-next-line import/prefer-default-export
export const multiStepForm = [
  {
    name: FORM_STEPS.CAR_REGISTRATION,
    title: (
      <Translation>
        {t => (
          <>
            {t(
              taxingFormMessages.taxingProcessForm.carRegistrationStep.title(),
            )}
          </>
        )}
      </Translation>
    ),
    subtitle: (
      <Translation>
        {t => (
          <>
            {t(
              taxingFormMessages.taxingProcessForm.carRegistrationStep.subtitle(),
            )}
          </>
        )}
      </Translation>
    ),
    backgroundImage: taxStepBg,
    renderAsCompInsideForm: true,
    fields: [
      /*      {
        name: FIELD_NAMES.CAR_REGISTRATION_1,
        placeholder: '## ### ###',
        label: 'Immatriculation',
        type: 'number',
        limit: 8,
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
          validate: (v: string) => {
            const val = v.replace(/\s/g, '');
            return (
              (val.length > 0 && val.length <= 8) || (
                <Translation>
                  {t => (
                    <>{t(globalMessages.errors.multiInput.carRegistration())}</>
                  )}
                </Translation>
              )
            );
          },
        },
      },
      {
        name: FIELD_NAMES.CAR_REGISTRATION_2,
        type: 'select',
        options: CAR_PLATES_LETTERS,
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
        },
      },
      {
        name: FIELD_NAMES.CAR_REGISTRATION_3,
        placeholder: '###',
        label: 'Immatriculation',
        type: 'number',
        limit: 3,
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
          validate: (v: string) =>
            (v.length > 0 && v.length <= 3) || (
              <Translation>
                {t => (
                  <>{t(globalMessages.errors.multiInput.carRegistration())}</>
                )}
              </Translation>
            ),
        },
      }, */
      /* {
        name: FIELD_NAMES.FISCAL_HORSE_POWER,
        placeholder: 'Puissance fiscale',
        label: 'Puissance fiscale',
        type: 'number',
        limit: 4,
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
        },
      }, */
      /*   {
        name: FIELD_NAMES.FUEL,
        placeholder: 'type de carurant',
        label: 'Type de carurant',
        type: 'select',
        options: FUEL_OPTIONS,
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
        },
      }, */
      /* {
        name: FIELD_NAMES.YEAR,
        placeholder: 'Année à régler',
        label: 'Année à régler',
        type: 'select',
        options: YEAR_OPTIONS,
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
        },
      }, */
    ],
  },
  {
    name: FORM_STEPS.FULL_NAME,
    title: (
      <Translation>
        {t =>
          t(taxingFormMessages.taxingProcessForm.fullNameStep.title()) as string
        }
      </Translation>
    ),
    subtitle: (
      <Translation>
        {t =>
          t(
            taxingFormMessages.taxingProcessForm.fullNameStep.subtitle(),
          ) as string
        }
      </Translation>
    ),
    backgroundImage: infoBg,
    fields: [
      {
        name: FIELD_NAMES.LAST_NAME,
        placeholder: (
          <Translation>
            {t =>
              t(
                taxingFormMessages.taxingProcessForm.fullNameStep.fields.lastNameLabel(),
              ) as string
            }
          </Translation>
        ),
        label: (
          <Translation>
            {t =>
              t(
                taxingFormMessages.taxingProcessForm.fullNameStep.fields.lastNameLabel(),
              ) as string
            }
          </Translation>
        ),
        type: 'text',
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
        },
      },
      {
        name: FIELD_NAMES.FIRST_NAME,
        placeholder: (
          <Translation>
            {t =>
              t(
                taxingFormMessages.taxingProcessForm.fullNameStep.fields.firstNameLabel(),
              ) as string
            }
          </Translation>
        ),
        label: (
          <Translation>
            {t =>
              t(
                taxingFormMessages.taxingProcessForm.fullNameStep.fields.firstNameLabel(),
              ) as string
            }
          </Translation>
        ),
        type: 'text',
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
        },
      },
    ],
  },
  {
    name: FORM_STEPS.CONTACT_INFO,
    title: (
      <Translation>
        {t => (
          <>{t(taxingFormMessages.taxingProcessForm.contactInfoStep.title())}</>
        )}
      </Translation>
    ),
    subtitle: (
      <Translation>
        {t => (
          <>
            {t(taxingFormMessages.taxingProcessForm.contactInfoStep.subtitle())}
          </>
        )}
      </Translation>
    ),
    backgroundImage: contactBg,
    fields: [
      {
        name: FIELD_NAMES.EMAIL_ADDRESS,
        placeholder: (
          <Translation>
            {t => (
              <>
                {t(
                  taxingFormMessages.taxingProcessForm.contactInfoStep.fields.emailLabel(),
                )}
              </>
            )}
          </Translation>
        ),
        label: (
          <Translation>
            {t => (
              <>
                {t(
                  taxingFormMessages.taxingProcessForm.contactInfoStep.fields.emailLabel(),
                )}
              </>
            )}
          </Translation>
        ),
        type: 'email',
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.emailNotValid())}</>}
              </Translation>
            ),
          },
          /* validate: (compareVal: string) => (v: string) =>
            v === compareVal || (
              <Translation>
                {t => <>{t(globalMessages.errors.emailNotIdentical())}</>}
              </Translation>
            ), */
        },
      },
      {
        name: FIELD_NAMES.CONFIRMED_EMAIL_ADDRESS,
        placeholder: (
          <Translation>
            {t => (
              <>
                {t(
                  taxingFormMessages.taxingProcessForm.contactInfoStep.fields.confirmedEmailLabel(),
                )}
              </>
            )}
          </Translation>
        ),
        label: (
          <Translation>
            {t => (
              <>
                {t(
                  taxingFormMessages.taxingProcessForm.contactInfoStep.fields.confirmedEmailLabel(),
                )}
              </>
            )}
          </Translation>
        ),
        type: 'email',
        disablePaste: true,
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.emailNotValid())}</>}
              </Translation>
            ),
          },
          validate: (compareVal: string) => (v: string) =>
            v === compareVal || (
              <Translation>
                {t => <>{t(globalMessages.errors.emailNotIdentical())}</>}
              </Translation>
            ),
        },
      },
      {
        name: FIELD_NAMES.PHONE_NUMBER,
        placeholder: (
          <Translation>
            {t => (
              <>
                {t(
                  taxingFormMessages.taxingProcessForm.contactInfoStep.fields.telephoneLabel(),
                )}
              </>
            )}
          </Translation>
        ),
        label: (
          <Translation>
            {t => (
              <>
                {t(
                  taxingFormMessages.taxingProcessForm.contactInfoStep.fields.telephoneLabel(),
                )}
              </>
            )}
          </Translation>
        ),
        extraLabel: (
          <Translation>
            {t => (
              <>
                {t(
                  taxingFormMessages.taxingProcessForm.contactInfoStep.fields.countryLabel(),
                )}
              </>
            )}
          </Translation>
        ),
        type: 'phone',
        format: '+212 ## ## ### ###',
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
          validate: (country: string) => (v: string) =>
            validatePhoneNumber(country, v) || (
              <Translation>
                {t => <>{t(globalMessages.errors.phoneNumberNotValid())}</>}
              </Translation>
            ),
        },
      },
      {
        name: FIELD_NAMES.RECAPTCHA,
        placeholder: 'recaptcha',
        label: 'recaptcha',
        type: 'recaptcha',
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
        },
      },
      {
        name: FIELD_NAMES.ACCEPTED_TERMS,
        placeholder: '',
        label: '',
        type: 'checkbox',
        rules: {
          required: {
            value: true,
            message: (
              <Translation>
                {t => <>{t(globalMessages.errors.inputRequired())}</>}
              </Translation>
            ),
          },
        },
      },
    ],
  },
  {
    name: FORM_STEPS.RECAP,
    title: (
      <Translation>
        {t => (
          <>{t(taxingFormMessages.taxingProcessForm.paymentStep.title())}</>
        )}
      </Translation>
    ),
    subtitle: (
      <Translation>
        {t => (
          <>{t(taxingFormMessages.taxingProcessForm.paymentStep.subtitle())}</>
        )}
      </Translation>
    ),
    backgroundImage: recapBg,
    render: () => <Recap />,
  },
];
