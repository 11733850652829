import { memo } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config';
import { themeType } from '../../context/types';

// const TEST_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

interface GRecaptchaProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (token: string | null) => void;
  themeMode: themeType;
}

function GRecaptcha({ onChange, themeMode = 'light' }: GRecaptchaProps) {
  return (
    <ReCAPTCHA
      sitekey={config.RECAPTCHA_SITE_KEY as any}
      size="normal"
      onChange={onChange}
      theme={themeMode}
    />
  );
}

export default memo(GRecaptcha);
