import { _t } from '../../utils/messages';

// eslint-disable-next-line import/prefer-default-export
export const taxingFormMessages = {
  taxingProcessForm: {
    fullNameStep: {
      title: () => _t('taxingProcessForm.fullNameStep.title', 'Nom et prénom'),
      subtitle: () =>
        _t(
          'taxingProcessForm.fullNameStep.subtitle',
          'Veuillez saisir votre nom et prénom',
        ),
      fields: {
        lastNameLabel: () =>
          _t('taxingProcessForm.fields.lastNameLabel', 'Nom'),
        firstNameLabel: () =>
          _t('taxingProcessForm.fields.firstNameLabel', 'Prénom'),
      },
    },
    contactInfoStep: {
      title: () => _t('taxingProcessForm.contactInfoStep.title', 'coordonnées'),
      subtitle: () =>
        _t(
          'taxingProcessForm.contactInfoStep.subtitle',
          'Veuillez saisir vos coordonnées',
        ),
      fields: {
        emailLabel: () => _t('taxingProcessForm.fields.emailLabel', 'Email'),
        confirmedEmailLabel: () =>
          _t(
            'taxingProcessForm.fields.confirmedEmailLabel',
            'CONFIRMATION ADRESSE EMAIL',
          ),
        telephoneLabel: () =>
          _t('taxingProcessForm.fields.telephoneLabel', 'TÉLÉPHONE'),
        countryLabel: () => _t('taxingProcessForm.fields.countryLabel', 'Pays'),
      },
    },
    carRegistrationStep: {
      title: () =>
        _t('taxingProcessForm.carRegistrationStep.title', 'Mon véhicule'),
      subtitle: () =>
        _t(
          'taxingProcessForm.carRegistrationStep.subtitle',
          'Veuillez saisir votre immatriculation et caractéristiques de votre véhicule',
        ),
      fields: {
        carPlateNumberLabel: () =>
          _t(
            'taxingProcessForm.carRegistrationStep.fields.carPlateNumberLabel',
            'Immatriculation',
          ),
        fiscalPowerLabel: () =>
          _t(
            'taxingProcessForm.carRegistrationStep.fields.fiscalPowerLabel',
            'Puissance fiscale',
          ),
        fuelLabel: () =>
          _t(
            'taxingProcessForm.carRegistrationStep.fields.fuelLabel',
            'Carburant',
          ),
        yearLabel: () =>
          _t(
            'taxingProcessForm.carRegistrationStep.fields.yearLabel',
            "Paiment de l'année",
          ),
        carLabel: () =>
          _t(
            'taxingProcessForm.carRegistrationStep.fields.carLabel',
            'Véhicule',
          ),
      },
    },
    paymentStep: {
      title: () => _t('taxingProcessForm.paymentStep.title', 'récapitulatif'),
      subtitle: () =>
        _t(
          'taxingProcessForm.paymentStep.subtitle',
          'Ci-dessous les détails de votre règlement',
        ),
      content: {
        fees: () =>
          _t('taxingProcessForm.paymentStep.content.fees', 'Frais de service'),
        amount: () =>
          _t(
            'taxingProcessForm.paymentStep.content.amount',
            'Montant a régler',
          ),
      },
      action: () =>
        _t('taxingProcessForm.paymentStep.action', 'Payez Maintenant'),
    },
    actionButtons: {
      submit: {
        title: () =>
          _t('taxingProcessForm.actionsButtons.submit.title', 'continuer'),
        subtitle: () =>
          _t(
            'taxingProcessForm.actionsButtons.submit.subtitle',
            'ou cliquer sur entrée',
          ),
      },
      goBack: {
        title: () =>
          _t('taxingProcessForm.actionsButtons.goBack.title', 'Retour'),
      },
    },
  },
};
