import styled, { useTheme } from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { Translation, useTranslation } from 'react-i18next';
import { memo } from 'react';
import { messages as globalMessages } from '../../components/messages';
import CustomInput from '../../components/CustomInput';
import Select from '../../components/Select';
import { ThemeInterface } from '../../components/Theme';
import {
  CAR_PLATES_LETTERS,
  FIELD_NAMES,
  FUEL_OPTIONS,
  YEAR_OPTIONS,
} from '../../utils/data';
import { InputGroup } from './styles';

import { taxingFormMessages } from './messages';

const StyledCatTaxInputContainer = styled.div`
  display: flex;
  width: 49%;
  max-width: 100%;
  height: 70px;
  border-radius: 35px;
  padding: 10px 30px;
  ${({ theme }) =>
    theme.mode === 'light'
      ? `
        background: ${theme.colors.lightGray};  
        input {
          background-color: ${theme.colors.lightGray};
        }
        .styled-select__control {
          background-color: ${theme.colors.lightGray};
        }
      `
      : `
      background: ${theme.colors.mainBackground};
      box-shadow: 0 0 20px 0 ${theme.colors.shadow};
    `}
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 60px;
  }
`;

const StyledErrorMessage = styled.p`
  color: ${props => props.theme.colors.danger};
  margin-top: 20px;
  font-weight: bold;
`;

export const GridContainer = styled.div`
  margin-top: 52px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 640px) {
    margin-top: 30px;
  }
`;

export const GridItem = styled.div`
  width: 49%;
  margin-bottom: 22px;
  @media screen and (max-width: 640px) {
    width: 100%;
    margin-bottom: 0;
  }
`;

const CAR_TAX_RULES: any = {
  [FIELD_NAMES.CAR_REGISTRATION_1]: {
    required: {
      value: true,
      message: (
        <Translation>
          {t => <>{t(globalMessages.errors.inputRequired())}</>}
        </Translation>
      ),
    },
    validate: (v: string) => {
      const val = v.replace(/\s/g, '');
      return (
        (val.length > 0 && val.length <= 8) || (
          <Translation>
            {t => <>{t(globalMessages.errors.multiInput.carRegistration())}</>}
          </Translation>
        )
      );
    },
  },
  [FIELD_NAMES.CAR_REGISTRATION_3]: {
    required: {
      value: true,
      message: (
        <Translation>
          {t => <>{t(globalMessages.errors.inputRequired())}</>}
        </Translation>
      ),
    },
    validate: (v: string) =>
      (v.length > 0 && v.length <= 3) || (
        <Translation>
          {t => <>{t(globalMessages.errors.multiInput.carRegistration())}</>}
        </Translation>
      ),
  },

  DEFAULT: {
    required: {
      value: true,
      message: (
        <Translation>
          {t => <>{t(globalMessages.errors.inputRequired())}</>}
        </Translation>
      ),
    },
  },
};

function CarTaxStep() {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const theme = useTheme();
  // console.log('CAR TAX STEP', { errors });

  const { t } = useTranslation();

  return (
    <>
      <StyledCatTaxInputContainer>
        <Controller
          name={FIELD_NAMES.CAR_REGISTRATION_1}
          control={control}
          rules={CAR_TAX_RULES[FIELD_NAMES.CAR_REGISTRATION_1]}
          render={({ field }) => (
            <CustomInput
              type="serial"
              {...field}
              limit={8}
              placeholder="## ### ###"
            />
          )}
        />
        <Controller
          name={FIELD_NAMES.CAR_REGISTRATION_2}
          control={control}
          rules={CAR_TAX_RULES.DEFAULT}
          render={({ field }) => (
            <Select
              {...field}
              options={CAR_PLATES_LETTERS}
              defaultValue={CAR_PLATES_LETTERS[0]}
              isRtl={(theme as ThemeInterface).rtl}
            />
          )}
        />
        <Controller
          name={FIELD_NAMES.CAR_REGISTRATION_3}
          control={control}
          render={({ field }) => (
            <CustomInput type="serial" {...field} limit={3} placeholder="###" />
          )}
          rules={CAR_TAX_RULES[FIELD_NAMES.CAR_REGISTRATION_3]}
        />
      </StyledCatTaxInputContainer>

      {(errors[FIELD_NAMES.CAR_REGISTRATION_1] ||
        errors[FIELD_NAMES.CAR_REGISTRATION_2] ||
        errors[FIELD_NAMES.CAR_REGISTRATION_3]) && (
        <StyledErrorMessage>
          {errors[FIELD_NAMES.CAR_REGISTRATION_1]?.message ||
            errors[FIELD_NAMES.CAR_REGISTRATION_2]?.message ||
            errors[FIELD_NAMES.CAR_REGISTRATION_3]?.message}
        </StyledErrorMessage>
      )}
      <GridContainer>
        <GridItem>
          <InputGroup>
            <Controller
              name={FIELD_NAMES.FISCAL_HORSE_POWER}
              control={control}
              rules={CAR_TAX_RULES.DEFAULT}
              render={({ field }) => (
                <CustomInput
                  type="number"
                  {...field}
                  limit={4}
                  placeholder={t(
                    taxingFormMessages.taxingProcessForm.carRegistrationStep.fields.fiscalPowerLabel(),
                  )}
                  isForPaymentProcess
                />
              )}
            />
            {errors[FIELD_NAMES.FISCAL_HORSE_POWER] && (
              <p className="text-error">
                {errors[FIELD_NAMES.FISCAL_HORSE_POWER].message}
              </p>
            )}
          </InputGroup>
        </GridItem>
        <GridItem>
          <InputGroup>
            <Controller
              name={FIELD_NAMES.FUEL}
              control={control}
              rules={CAR_TAX_RULES.DEFAULT}
              render={({ field }) => (
                <Select
                  {...field}
                  options={FUEL_OPTIONS}
                  defaultValue={FUEL_OPTIONS[0]}
                  label={t(
                    taxingFormMessages.taxingProcessForm.carRegistrationStep.fields.fuelLabel(),
                  )}
                  isForPaymentProcess
                />
              )}
            />
            {errors[FIELD_NAMES.FUEL] && (
              <p className="text-error">{errors[FIELD_NAMES.FUEL].message}</p>
            )}
          </InputGroup>
        </GridItem>
        <GridItem>
          <InputGroup>
            <Controller
              name={FIELD_NAMES.YEAR}
              control={control}
              rules={CAR_TAX_RULES.DEFAULT}
              render={({ field }) => (
                <Select
                  {...field}
                  options={YEAR_OPTIONS}
                  defaultValue={YEAR_OPTIONS[0]}
                  label={t(
                    taxingFormMessages.taxingProcessForm.carRegistrationStep.fields.yearLabel(),
                  )}
                  isForPaymentProcess
                />
              )}
            />
            {errors[FIELD_NAMES.YEAR] && (
              <p className="text-error">{errors[FIELD_NAMES.YEAR].message}</p>
            )}
          </InputGroup>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default memo(CarTaxStep);
