import { useContext } from 'react';
import { GlobalContext } from '../context';
import { http, HTTP_METHODS } from '../utils/http';
import { SPECIAL_CODES } from '../utils/data';
import {
  getCmiProps,
  getCmiPropsFailed,
  getCmiPropsSuccess,
  getHashRequest,
  getHashSuccess,
  getUnpaidTaxesFailed,
  getUnpaidTaxesRequest,
  getUnpaidTaxesSuccess,
  nextStep,
  saveClientFailed,
  saveClientRequest,
  saveClientSuccess,
} from '../context/actions';
import {
  carRegistrationType,
  taxingFromFieldsType,
  UnpaidTaxType,
} from '../context/types';
import { errorToast, loadingToast, successToast } from '../utils/toast';
import { makePlateNumber, saveClientStateData } from '../utils/helpers';

export default function usePayment() {
  const { dispatch } = useContext(GlobalContext);

  async function handleGetCmiProps() {
    try {
      dispatch(getCmiProps());

      const data = await http('properties/data', HTTP_METHODS.POST);
      // console.log({ data });
      dispatch(getCmiPropsSuccess(data));
    } catch (error) {
      console.log('error getCmiProps', { error });
      // @ts-ignore
      dispatch(getCmiPropsFailed(error?.response?.message as string));
      throw error;
    }
  }

  async function saveClient({
    carRegistrationChunk1,
    carRegistrationChunk2,
    carRegistrationChunk3,
    email,
    firstName,
    phoneNumber,
    fuel,
    year,
    fiscalHorsePower,
    lastName,
  }: taxingFromFieldsType) {
    // const id = loadingToast();
    try {
      dispatch(saveClientRequest());
      const carPlate =
        `${carRegistrationChunk1}${carRegistrationChunk2}${carRegistrationChunk3}`.replace(
          /\s/g,
          '',
        );
      console.log('SAVING CLIENT', {
        carRegistrationChunk1,
        carRegistrationChunk2,
        carRegistrationChunk3,
        email,
        firstName,
        lastName,
        phoneNumber,
        fuel,
        year,
        fiscalHorsePower,
      });

      const data = await http('client', HTTP_METHODS.POST, {
        body: {
          email,
          nom: lastName?.trim(),
          prenom: firstName?.trim(),
          // telephone: phoneNumber?.replace('+212 (0)', '0').replace(/\s/g, ''),
          telephone: phoneNumber?.trim(),
          paiements: [
            {
              anneePaiement: year,
              codeCreance: SPECIAL_CODES.CODE_CREANCE,
              codeCreancier: SPECIAL_CODES.CODE_CREANCIER,
              dateCreation: new Date(),
              immatriculation: carPlate?.trim(),
              puissanceFiscale: fiscalHorsePower,
              status: 'SAISIE_INCOMPLETE',
              typeCarburant: fuel,
            },
          ],
        },
      });

      dispatch(saveClientSuccess(data));

      saveClientStateData(data);
      // successToast(id, 'Redirection encours ...');
    } catch (error) {
      // @ts-ignore
      dispatch(saveClientFailed(error.response.message));
      // @ts-ignore
      // errorToast(id, error.response.message);
    }
  }

  async function getUnpaidTaxes({
    carRegistrationChunk1,
    carRegistrationChunk2,
    carRegistrationChunk3,
    fuel,
    year,
    fiscalHorsePower,
    recaptcha,
  }: carRegistrationType & { recaptcha: string }) {
    // const id = loadingToast();
    try {
      dispatch(getUnpaidTaxesRequest());
      const carPlate = makePlateNumber({
        carRegistrationChunk1,
        carRegistrationChunk2,
        carRegistrationChunk3,
      });
      console.log('GETTING UNPAID TAXES', {
        carRegistrationChunk1,
        carRegistrationChunk2,
        carRegistrationChunk3,
        fuel,
        year,
        fiscalHorsePower,
        recaptcha,
      });

      const data: UnpaidTaxType = await http(
        'creancier/impayes',
        HTTP_METHODS.POST,
        {
          body: {
            codeCreance: SPECIAL_CODES.CODE_CREANCE,
            codeCreancier: SPECIAL_CODES.CODE_CREANCIER,
            recaptchaResponce: recaptcha,
            attributList: [
              {
                nomChamp: 'matricule',
                valeurChamp: carPlate,
              },
              {
                nomChamp: 'puissance_fiscale',
                valeurChamp: fiscalHorsePower,
              },
              {
                nomChamp: 'carburant_type',
                valeurChamp: fuel,
              },
              {
                nomChamp: 'periode_annee',
                valeurChamp: year,
              },
            ],
          },
        },
      );

      // TODO : REFACTOR BASED ON LEGACY PROJECT MARKETPLACE UI
      const listTax = data?.articleImpayesList?.find(
        item => item.typeArticle === 'CREANCE',
      );

      const car = data?.champDTOList?.find(
        e => e.nomChamp === 'marque',
      )?.valChamp;

      const totalTaxHT = listTax ? listTax.prixTTC : 0;
      const invoiceDate = listTax ? listTax.dateFacture : '';
      const totalTTC = data.montantTotalTTC;
      const oid = data.refTxFatourati;
      const id1 = listTax ? listTax.idArticle : '';
      const desc1 = listTax ? listTax.description : '';
      const total1 = listTax ? listTax.prixTTC : '';
      const listUnpaidFees = data?.articleImpayesList?.find(
        item => item.typeArticle === 'FRAIS',
      );
      const id2 = listUnpaidFees ? listUnpaidFees.idArticle : '';
      const desc2 = listUnpaidFees ? listUnpaidFees.description : '';
      const total2 = listUnpaidFees ? listUnpaidFees.prixTTC : '';

      const fees = listUnpaidFees ? listUnpaidFees.prixTTC : '';

      const nbrOfArticles = data?.articleImpayesList?.length;

      const fields = data?.champDTOList;
      const weirdStr = fields
        ?.map(e => `${e.nomChamp}:${e.valChamp}|`)
        .join('');
      const weirdParams: string =
        weirdStr?.substring(0, weirdStr?.length - 1) || '';

      const mappedData = {
        listTax,
        totalTaxHT,
        invoiceDate,
        totalTTC,
        oid,
        id1,
        desc1,
        total1,
        id2,
        desc2,
        total2,
        fees,
        nbrOfArticles,
        weirdParams,
        car,
      };

      console.log('unpaid taxed...', { data, mappedData });

      dispatch(getUnpaidTaxesSuccess(mappedData));
      // successToast(id, 'getting unpaid taxes successfully !');

      return mappedData;
    } catch (error) {
      // @ts-ignore
      dispatch(getUnpaidTaxesFailed(error?.response?.message));
      // @ts-ignore
      // errorToast(id, error.response.message);
      throw error;
    }
  }

  async function getHash({
    firstName,
    lastName,
    email,
    totalTTC,
    oid,
    total1,
    total2,
    nbrOfArticles,
    id1,
    id2,
    desc1,
    desc2,
    weirdParams,
  }: any) {
    // const id = loadingToast();
    try {
      dispatch(getHashRequest());
      const hash = await http('hash', HTTP_METHODS.POST, {
        body: {
          // amount: 2 ,
          oid,
          billToName: `${lastName} ${firstName}`,
          cf_params_global: weirdParams,
          cid: SPECIAL_CODES.CODE_CREANCE,
          desc1,
          desc2,
          email,
          fid: SPECIAL_CODES.CODE_CREANCIER,
          id1,
          id2,
          nbrArticle: nbrOfArticles,
          amount: totalTTC,
          total1,
          total2,
          /* total1: 1,
          total2: 1, */
        },
      });
      console.log({ hash });
      dispatch(getHashSuccess(hash));
      // successToast(id, 'getting hash code successfully !');
    } catch (error) {
      console.log('HASH', { error });
      // @ts-ignore
      // errorToast(id, error?.response.message);
      throw error;
    }
  }

  async function handlePaymentRequest(data: taxingFromFieldsType) {
    const id = loadingToast();
    try {
      await handleGetCmiProps();

      const taxData = await getUnpaidTaxes(data);
      await getHash({ ...data, ...taxData });

      await saveClient(data);

      successToast(id, 'Bien traitée 😃');
      dispatch(nextStep());
    } catch (error) {
      console.log('some thing went wrong ...', { error });
      // @ts-ignore
      errorToast(id, `${error?.response?.message || error?.message}`);
    }
  }

  return {
    handlePaymentRequest,
  };
}
