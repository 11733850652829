import { ChangeEvent, FocusEvent, forwardRef, memo, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import RSelect from 'react-select';
import { Label } from '../common';
import { InputStyled } from '../CustomInput';
import { StyledSelectForPaymentProcess } from '../Select/styles';
import { PhoneInputWrapper, PhoneWrapper } from './styles';
import { countriesList, countryPhones, flagStyles } from './helpers';

interface PhoneProps {
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  value?: string | number;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  country: string;
  // eslint-disable-next-line no-unused-vars
  setCountry: (v: string) => void;
  countryLabel: string;
}

const PhoneInput = forwardRef(
  (
    {
      name,
      disabled,
      autoFocus,
      placeholder,
      onChange,
      onBlur,
      value,
      hasError = false,
      country,
      setCountry,
      countryLabel,
    }: PhoneProps,
    ref,
  ) => {
    const defaultValue = useMemo(
      () => countriesList.find(({ value: v }) => v === country),
      [country],
    );

    // console.log({ phoneValue: value });

    return (
      <PhoneWrapper>
        <StyledSelectForPaymentProcess>
          <Label>{countryLabel}</Label>
          <RSelect
            defaultValue={defaultValue}
            onChange={(e: any) => {
              setCountry(e.value);
            }}
            options={countriesList}
            styles={flagStyles}
            className="react-select-container"
            classNamePrefix="react-select"
            isDisabled={disabled}
          />
        </StyledSelectForPaymentProcess>
        <PhoneInputWrapper>
          <Label htmlFor={name}>{placeholder}</Label>
          <InputStyled
            name={name}
            disabled={disabled}
            autoFocus={autoFocus}
            placeholder={placeholder}
            ref={ref}
            format={countryPhones[country].format}
            onChange={onChange}
            onBlur={onBlur}
            allowNegative={false}
            value={value}
            as={NumberFormat}
            allowEmptyFormatting
            hasError={hasError}
            mask="_"
            isForPaymentProcess
          />
        </PhoneInputWrapper>
      </PhoneWrapper>
    );
  },
);

export default memo(PhoneInput);
